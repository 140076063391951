import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import * as ticketController from '../../../../services/api/ticket';
import './TicketsPage.scss';

const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL;

export class TicketsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ws: new WebSocket(REACT_APP_WS_URL),
            dataSource: [],
        }
    }

    getTickets() {
        const { userId } = this.props;

        ticketController.getTickets()
            .then((res) => res.json())
            .then((json) => this.setState({
                dataSource: json,
                assignedTickets: _.filter(json, (ticket) => ticket.dashboardUserId === parseInt(userId) && !ticket.isCompleted)
            }))
    }

    handleFollowUpRequestClick(ticketId) {
        ticketController.followUpTicket(ticketId)
            .then((res) => res.json())
            .then((json) => this.getTickets())
    }

    afterMessageUpdate() {
        this.setState({ isTicketModalVisible: false });
        this.getTickets();
    }

    componentDidMount() {
        const { ws } = this.state;

        ws.addEventListener('message', (event) => {
            const json = JSON.parse(event.data);
            if (json.type === 'newTicketIn')
                this.getTickets();
        });

        this.getTickets();
    }

    render() {
        const { dataSource } = this.state;
        const columns = [{
            title: 'Sent at',
            dataIndex: 'createdAt',
            align: 'center',
            width: 150,
            render: (value, record) =>
                <div>
                    <div>{moment(value).format('DD/MM/YYYY')}</div>
                    <div>{moment(value).format('HH:mm')}</div>
                </div>
        }, {
            title: 'Zone',
            dataIndex: 'zone.name',
            align: 'center',
        }, {
            title: 'User',
            dataIndex: 'user.name',
            align: 'center',
            width: 300,
            render: (value, record) => record.contactNumber ? `${value} (${record.contactNumber})` : value
        }, {
            title: 'Request Type',
            dataIndex: 'requestType',
            align: 'center',
        }, {
            title: 'Description',
            dataIndex: 'message',
        }];

        return (
            <div className="TicketsPage">
                <div className="page-header">
                    <h2>Ticket History</h2>
                </div>
                <div className="ticket-table-list">
                    <Table
                        size="middle"
                        columns={columns}
                        dataSource={_.filter(dataSource, record => _.isNull(record.dashboardUserId))}
                        rowKey="id"
                        pagination={{
                            pageSize: 8
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: state.user.id,
});

export default connect(mapStateToProps)(TicketsPage);